<script setup lang="ts">
import { SurveyType, SourceType } from '@msl/survey-response-gateway-sdk';
import { onMounted, ref, watch } from 'vue';
import csatTemplate from './csat-embed-template.vue';
import npsTemplate from './nps-embed-template.vue';

const mappedSourceType: Record<string, string> = Object.keys(SourceType).reduce(
  (acc: Record<string, string>, key) => {
    acc[key] = key;
    return acc;
  },
  {}
);

const props = defineProps<{
  source: string;
  type: number | undefined;
  isIdentified: boolean;
  linkUrl: string;
  logo: string;
}>();
const { type, linkUrl } = props;
const isCopied = ref<boolean>(false);
const isUserIdentified = ref<boolean>(true);
const query = ref<string>('');

const csatTemplateRef = ref<InstanceType<typeof csatTemplate>>();
const npsTemplateRef = ref<InstanceType<typeof npsTemplate>>();

defineExpose({ csatTemplateRef, npsTemplateRef });

watch(
  () => props.source,
  () => {
    isCopied.value = false;
    platformSpecific();
  }
);

watch(
  () => props.isIdentified,
  () => {
    isUserIdentified.value = !isUserIdentified.value;
    platformSpecific();
  }
);

const platformSpecific = () => {
  const source = props.source;
  if (isUserIdentified.value === false) {
    query.value = `&source=${source}`;
    return;
  }
  const sourceParams: Record<string, string> = {
    [mappedSourceType.zendesk]:
      `&source=${source}&customer_email={{ticket.requester.email}}&team_member_email={{ticket.assignee.email}}` +
      '&company_name={{ticket.requester.organization.name}}&customer_id={{ticket.requester.id}}' +
      '&ticket_id={{ticket.id}}&ticket_subject={{ticket.title}}&team_member_first_name={{ticket.assignee.first_name}}' +
      '&team_member_last_name={{ticket.assignee.last_name}}&customer_first_name={{ticket.requester.first_name}}' +
      '&customer_last_name={{ticket.requester.last_name}}&t_group={{ticket.group.name}}',
    [mappedSourceType.customer_io]:
      `&source=${source}&customer_email={{customer.email}}&plan={{customer.plan}}&t_plan={{customer.plan}}` +
      '&t_channel=cio-default&t_campaign=NPS_1m',
    [mappedSourceType.vitally]:
      `&source=${source}&customer_email={{account.traits.ws_accountOwnerEmail}}&customer_id={{account.externalId}}` +
      '&plan={{account.traits.ws_plan}}&company_size={{account.traits.ws_PlanSeats}}&csm={{account.traits.vitally.custom.csmCopy}}',
  };

  query.value = sourceParams[source] || `&source=${source}`;
};

onMounted(async () => {
  platformSpecific();
});
</script>
<template>
  <div>
    <pre><code><csat-template
          v-if="type === SurveyType.csat" 
          ref="csatTemplateRef" 
          :platform-specific="query"
          :link-url="linkUrl"
        /><nps-template
          v-if="type === SurveyType.nps" 
          ref="npsTemplateRef" 
          :platform-specific="query"
          :link-url="linkUrl"
          :logo="logo"
        />
      </code>
    </pre>
  </div>
</template>
<style scoped lang="scss">
div:has(code) {
  background-color: rgb(var(--color-gray-50));
  padding: 10px;
  border-radius: 5px 5px 0 0;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none; // Hide scrollbars – Chrome
  }

  scrollbar-width: none; // Hide scrollbars – Firefox
  -ms-overflow-style: none; // Hide scrollbars – IE/Edge
}
</style>
